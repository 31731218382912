import React from 'react';
import '../skills/skills.css';
import { ImCheckmark2 } from 'react-icons/im';
const Skills = () => {
  return (
    <>
      <section id="skills">
        <h5>Some of the technologies I've been working with lately</h5>
        <h2>My Toolkit</h2>
        <div className="container skills__container">
          <article className="skills">
            <div className="skills__head">
              <h3>Frameworks and Libraries</h3>
            </div>
            <ul className="skills__list">
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Django</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Django REST Framework</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Node JS</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Express JS</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>React JS</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Next JS</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Redux</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>MediaPipe</p>
              </li>
            </ul>
          </article>
          {/*  END - Frameworks and Libraries  */}
          <article className="skills">
            <div className="skills__head">
              <h3>Development Tools</h3>
            </div>
            <ul className="skills__list">
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Git</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Postman</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Docker</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>AWS</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Swagger</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Jira</p>
              </li>
            </ul>
          </article>
          {/* END - Development Tools */}
          <article className="skills">
            <div className="skills__head">
              <h3>Database</h3>
            </div>
            <ul className="skills__list">
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>PostgreSQL</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>MySQL</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>MongoDB</p>
              </li>
            </ul>
          </article>
          {/* END OF Database */}
          <article className="skills">
            <div className="skills__head">
              <h3>Frontend Technologies</h3>
            </div>
            <ul className="skills__list">
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>HTML</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>CSS</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Tailwind CSS</p>
              </li>
              <li>
                <ImCheckmark2 className="skills__list-icon" />
                <p>Bootstrap</p>
              </li>
            </ul>
          </article>
          {/* END - Frontend Technologies */}
        </div>
      </section>
    </>
  );
};

export default Skills;
